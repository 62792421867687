// @ts-nocheck
"use client";

import { useEffect, useState } from "react";
import { Progress } from "@/components/ui/progress"; // Adjust the import path as needed

export default function Loading() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 100); // Adjust the speed as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-2xl font-bold mb-4">Loading...</h1>
        <div className="w-64 mx-auto">
          <Progress min={1} value={progress} />
        </div>
      </div>
    </div>
  );
}
